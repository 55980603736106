import React, { useEffect, useState, useContext } from "react";
import Header from "../../Components/Header2";
import { Row, Col, Button, Select } from "antd";
import { settings } from "../../Components/utils/Settings";
import axios from "axios";
import Loader from "react-js-loader";
import Footer from "../../Components/Footer";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import FileSaver from "file-saver";
import PupilContext from "../../Components/context/PupilContext";
import { Link, useHistory } from "react-router-dom";

const Summary = (props) => {
  const history = useHistory();
  const [clientsArray, setclientsArray] = useState([]);
  const [KidsTotal, setKidsTotal] = useState("");
  const [SSATotal, setSSATotal] = useState("");
  const [ks5Total, setks5Total] = useState("");

  const [ks5missingten, setks5missingten] = useState("");
  const [ssamissingten, setssamissingten] = useState("");
  const [DTotal, setDTotal] = useState("");
  const [SSADTotal, setSSADTotal] = useState("");
  const [ks5DTotal, setks5DTotal] = useState("");
  const [MissingData, setMissingData] = useState("");
  const [Missingallks5, setMissingallks5] = useState("");
  const [Missingallssa, setMissingallssa] = useState("");
  const [MissingSess, setMissingSess] = useState("");
  const [MissingSessper, setMissingSessper] = useState("");
  const [Missingperks5, setMissingperks5] = useState("");
  const [Missingperssa, setMissingperssa] = useState("");
  const [wondeperks5, setwondeperks5] = useState("");
  const [wondeperssa, setwondeperssa] = useState("");
  const [countschool, setcountschool] = useState("");
  const [countschoolKs5, setcountschoolKs5] = useState("");
  const [countschoolSSA, setcountschoolSSA] = useState("");
  const [wondekS5, setwondekS5] = useState("");
  const [wondeSsa, setwondeSsa] = useState("");
  const [wondCountt, setwondCountt] = useState("");
  const [perWonde, setperWonde] = useState("");
  const [WondeSchool, setWondeSchool] = useState("");
  const [WondeSchoolks5, setWondeSchoolks5] = useState("");
  const [wondwschoolssa, setwondwschoolssa] = useState("");
  const [schoolonwonde, setschoolonwonde] = useState("");
  const [schoolonwondeks5, setschoolonwondeks5] = useState("");
  const [schoolwondeper, setschoolwondeper] = useState("");

  const [exportLoading, setExportLoading] = useState(false);
  const [AcdValue, setAcdValue] = useState("2024-25");
  const {
    setSchoolsId,
    setSortingValue,
    cictype,
    setcictype,
    setselectedClient,
    setdeletevalues,
    setKS5,
  } = useContext(PupilContext);

  const Iduser = localStorage.getItem("userId");

  useEffect(() => {
    console.log(AcdValue);
    setclientsArray("");
    const apiurl = `${settings.API_HOST}/rest/fetchAllSchoolsSummaryData?userId=${Iduser}&academicYear=${AcdValue}`;

    axios.get(apiurl).then((res) => {
      console.log(res);

      const Allclientsdata = res?.data?.allSchoolsSummaryDataList;
      const abc = [];
      let sum = 0;
      let missum = 0;
      let sesssum = 0;
      let misspersum = 0;
      let schoolsum = 0;
      let schoolsumks5 = 0;
      let wondesum = 0;
      let persum = 0;
      let wondeSchoolPer = 0;
      let wondeschoolscountks5 = 0;
      let Dtotal = 0;
      let ks5kid = 0;
      let Ssatotal = 0;
      let ks5tenmissing = 0;
      let KS5Dtotal = 0;
      let KS5MissingAll = 0;
      let KS5Wonde = 0;

      const datafortable = Allclientsdata?.map((item) => {
        console.log(item);
        const kidsdata = item.kidCount;
        const sessionDAta = item?.aboveTenSessionsMissingKidCount;
        const MissingChild = item?.allSessionsMissingKidCount;
        const Missingatten = item?.allSessionsMissingPercentage;
        const schoolcount = item?.subSchoolCount;
        const wondeSchoolCount = item?.wondeKidCount;
        const wondeSchoolPercentage = item?.wondeSchoolPercentage;
        const schoolper = item?.wondeSchoolCount;
        const Dper = item?.kidsCountWithCodeD;

        const totalwondcountks5 = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce((sum, item) => sum + (item?.wondeSchoolCount || 0), 0);

        const ks5schoolcount = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce((sum, item) => sum + (item?.subSchoolCount || 0), 0);

        const totalKidCount = Allclientsdata.filter((item) =>
          item?.schoolName.includes("KS5")
        ).reduce((sum, item) => sum + (item?.kidCount || 0), 0);

        const totalksmissingten = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce(
          (sum, item) => sum + (item?.aboveTenSessionsMissingKidCount || 0),
          0
        );

        const totalksdtotal = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce((sum, item) => sum + (item?.kidsCountWithCodeD || 0), 0);

        const Missingallks5 = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce(
          (sum, item) => sum + (item?.allSessionsMissingKidCount || 0),
          0
        );

        const ks5wonde = Allclientsdata?.filter((item) =>
          item?.schoolName.includes("KS5")
        )?.reduce((sum, item) => sum + (item?.wondeKidCount || 0), 0);

        //console.log("Total Kid Count for KS5 Schools:", totalksdtotal);

        // console.log(schoolper);
        wondeschoolscountks5 = totalwondcountks5;
        schoolsumks5 = ks5schoolcount;
        KS5Wonde = ks5wonde;
        KS5MissingAll = Missingallks5;
        KS5Dtotal = totalksdtotal;
        ks5tenmissing = totalksmissingten;
        ks5kid = totalKidCount;

        sesssum += sessionDAta;
        sum += kidsdata;
        missum += MissingChild;
        misspersum += Missingatten;
        schoolsum += schoolcount;
        wondesum += wondeSchoolCount;
        persum += wondeSchoolPercentage;
        wondeSchoolPer += schoolper;
        Dtotal += Dper;
        Ssatotal = sum - ks5kid;

        console.log("KS5 Schools:", sum, ks5kid, sum - ks5kid);

        return { DataArray: item };
      });

      const newvalue = (missum / sum) * 100;
      const ks5newvalue = (KS5MissingAll / ks5kid) * 100;

      const ks5wonde = (KS5Wonde / ks5kid) * 100;
      const countperschool = (wondeSchoolPer / schoolsum) * 100;
      const wondeperschoolks5 = (wondeschoolscountks5 / schoolsumks5) * 100;
      const wondperschool = (wondesum / sum) * 100;

      //console.log(countperschool.toFixed());

      setSSATotal(Ssatotal);
      setperWonde(countperschool.toFixed(2));
      setwondCountt(wondesum);
      setwondekS5(KS5Wonde);
      setwondeSsa(wondesum - KS5Wonde);
      setcountschool(schoolsum);
      setcountschoolKs5(schoolsumks5);
      setcountschoolSSA(schoolsum - schoolsumks5);
      setMissingSessper(newvalue.toFixed(2));
      setMissingperks5(ks5newvalue.toFixed(2));

      setwondeperks5(ks5wonde.toFixed(2));
      setMissingSess(missum);
      setMissingData(sesssum);
      setMissingallks5(KS5MissingAll);
      setMissingallssa(missum - KS5MissingAll);
      setKidsTotal(sum);
      setks5Total(ks5kid);
      setks5missingten(ks5tenmissing);
      setssamissingten(sesssum - ks5tenmissing);
      setks5DTotal(KS5Dtotal);
      setDTotal(Dtotal);
      setSSADTotal(Dtotal - KS5Dtotal);
      setWondeSchool(wondeSchoolPer);
      setWondeSchoolks5(wondeschoolscountks5);
      setwondwschoolssa(wondeSchoolPer - wondeschoolscountks5);
      setschoolonwonde(wondperschool.toFixed(2));
      setschoolonwondeks5(wondeperschoolks5.toFixed(2));
      const wondessanew = wondeSchoolPer - wondeschoolscountks5;
      console.log(wondessanew);
      const totaslofwondekid = schoolsum - schoolsumks5;
      console.log(totaslofwondekid);
      const ssaper = (wondessanew / totaslofwondekid) * 100;
      setschoolwondeper(ssaper?.toFixed(2));
      setclientsArray(datafortable);
      const ssanewvalue = (Missingallssa / Ssatotal) * 100;
      setMissingperssa(ssanewvalue.toFixed(2));
      console.log(Ssatotal);
      const newvalueda = wondesum - KS5Wonde;
      const ssawondevalue = (newvalueda / Ssatotal) * 100;
      setwondeperssa(ssawondevalue.toFixed(2));
    });
  }, [AcdValue]);

  const HandleMissingsredirect = (e, SchoolId, name) => {
    console.log(name);
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }
    //setdeletevalues(true);
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId);
    setSortingValue("allMissing");
    history.push("/attendance-caller");
  };

  const HandleExport = () => {
    const ApiEndpoint = `${settings.API_HOST}/rest/fetchAllSchoolsSummaryDataExcel`;

    axios({
      url: ApiEndpoint,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `Summary-report.xlsx`
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
        toast.success(
          "Attendance report exported and downloaded successfull as EXCEL Format",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
          }
        );
        Swal.close();
        setExportLoading(false);
      })
      .catch((e) => {
        toast.error("Sorry something went wrong ! Try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      });
  };

  const HandleMissingsess = (e, SchoolId, name) => {
    console.log(name);
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Medway KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Calderdale KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }

    console.log(name);
    //console.log(e,SchoolId)
    //setSchoolsId(SchoolId),
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId);
    // setdeletevalues(true);
    setSortingValue("missingMoreThenTen");
    history.push("/attendance-caller");
  };

  const HandleDcode = (e, SchoolId, name) => {
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Medway KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }
    setdeletevalues(false);
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId);
    setSortingValue("Code D Kids");
    history.push("/attendance-caller");
  };

  const HandleMissingsall = (e, SchoolId, name) => {
    console.log(name);
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Medway KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }
    //setdeletevalues(true);
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId);
    setSortingValue("allMissing");
    history.push("/attendance-caller");
  };

  const color = "#fff";

  return (
    <>
      {/* <div className="custom-head">
        <Header />
      </div> */}

      <h1 className="Head-title" style={{ color: "#fff" }}>
        Summary
      </h1>

      {clientsArray == "" ? (
        <div className="cube-loader">
          <Loader type="ping-cube" bgColor={color} color={color} size={100} />
        </div>
      ) : (
        <div className="table-group">
          <table
            className="table-auto w-100 child-table tablesection customtablenew"
            style={{ width: "61%", marginTop: "10px" }}
          >
            <Select
              onSelect={(e) => {
                setAcdValue(e);
              }}
              style={{
                position: "absolute",
                marginLeft: "15px",
                top: "10%",
              }}
              className="customacad"
              defaultValue={AcdValue}
            >
              <option value="2024-25">2024-25</option>
              <option value="2023-24">2023-24</option>
            </Select>
            <div className="head-tables pupilsno">
              <p className="table-title"> No of pupils</p>
            </div>
            <thead className="thead-title">
              <tr>
                <th
                  className="cursor-pointer forsmall"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ width: "138px" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Clients</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable d-codetb"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">No of D Codes</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable "
                  style={{ maxWidth: "113px" }}

                  // onClick={() => setCustomSorting("pupilcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">More than 10 sessions missing</span>
                    {/* <span className="mr-4">{showArrow("pupilcount")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ maxWidth: "113px", textAlign: "center" }}
                  // onClick={() => setCustomSorting("wondcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Missing all attendance</span>
                    {/* <span className="mr-4">{showArrow("wondcount")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center", width: "127px" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% Missing all attendance</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">On Wonde</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% on Wonde</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {clientsArray?.map((items) => (
                <>
                  <tr className="align-texts">
                    <td className="schoolpupils nameofschools">
                      {items?.DataArray?.schoolName}
                    </td>
                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.kidCount}
                    </td>

                    <td
                      className="schoolpupils miss-session"
                      id="dpupil"
                      onClick={(e) =>
                        HandleDcode(
                          e,
                          items?.DataArray?.schoolId,
                          items?.DataArray?.schoolName
                        )
                      }
                    >
                      {items?.DataArray?.kidsCountWithCodeD}
                    </td>

                    <td
                      className="schoolpupils miss-session"
                      id="tenpupil"
                      onClick={(e) =>
                        HandleMissingsess(
                          e,
                          items?.DataArray?.schoolId,
                          items?.DataArray?.schoolName
                        )
                      }
                    >
                      {items?.DataArray?.aboveTenSessionsMissingKidCount}
                    </td>
                    <td
                      className="schoolpupils miss-session"
                      id="allpupil"
                      onClick={(e) =>
                        HandleMissingsall(
                          e,
                          items?.DataArray?.schoolId,
                          items?.DataArray?.schoolName
                        )
                      }
                    >
                      {items?.DataArray?.allSessionsMissingKidCount}
                    </td>
                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.allSessionsMissingPercentage}
                    </td>

                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.wondeKidCount}
                    </td>

                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.wondeKidPercentage}
                    </td>
                  </tr>
                </>
              ))}
              <tr className="align-texts">
                <td className="schoolpupils nameofschools">Total KS5</td>

                <td className="schoolpupils miss-session">{ks5Total}</td>
                <td className="schoolpupils miss-session">{ks5DTotal}</td>
                <td className="schoolpupils miss-session">{ks5missingten}</td>
                <td className="schoolpupils miss-session">{Missingallks5}</td>
                <td className="schoolpupils miss-session">{Missingperks5}</td>
                <td className="schoolpupils miss-session">{wondekS5}</td>
                <td className="schoolpupils miss-session">{wondeperks5}</td>
              </tr>

              <tr className="align-texts">
                <td className="schoolpupils nameofschools">Total SSA</td>

                <td className="schoolpupils miss-session">{SSATotal}</td>
                <td className="schoolpupils miss-session">{SSADTotal}</td>
                <td className="schoolpupils miss-session">{ssamissingten}</td>
                <td className="schoolpupils miss-session">{Missingallssa}</td>
                <td className="schoolpupils miss-session">{Missingperssa}</td>
                <td className="schoolpupils miss-session">{wondeSsa}</td>
                <td className="schoolpupils miss-session">{wondeperssa}</td>
              </tr>
              <tr className="align-texts">
                <td className="schoolpupils nameofschools">Grand Total</td>

                <td className="schoolpupils miss-session">{KidsTotal}</td>
                <td className="schoolpupils miss-session">{DTotal}</td>
                <td className="schoolpupils miss-session">{MissingData}</td>
                <td className="schoolpupils miss-session">{MissingSess}</td>
                <td className="schoolpupils miss-session">{MissingSessper}</td>
                <td className="schoolpupils miss-session">{wondCountt}</td>
                <td className="schoolpupils miss-session">{schoolonwonde}</td>
              </tr>
            </tbody>
          </table>

          <table
            className="table-auto w-100 child-table tablesection customtablenew smalltable"
            style={{ width: "30%", marginTop: "10px" }}
          >
            <div className="head-tables noschools">
              <p className="table-title"> No of Schools</p>
            </div>
            <Button
              className="export-button"
              shape="round"
              onClick={HandleExport}
              style={{
                position: "absolute",
                right: "5%",
                // color: "#5d45a1",
                top: "10%",
                //background: "#5d45a1",
                //marginTop: "2px",
              }}
            >
              {" "}
              {exportLoading ? <div className="spinner"></div> : "Export"}
            </Button>
            <thead className="thead-title">
              <tr>
                <th
                  className="cursor-pointer"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ width: "185px" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Clients</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>

                <th
                  className="total-thead aligntable"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ minWidth: "100px", width: "3%" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>
                <th
                  className="wonde-total aligntable"
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total on Wonde</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>
                <th
                  className="wonde-total aligntable"
                  style={{ width: "21%" }}

                  // onClick={() => setCustomSorting("pupilcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% of Wonde</span>
                    {/* <span className="mr-4">{showArrow("pupilcount")}</span> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="mt-4">
              {clientsArray?.map((items) => (
                <tr>
                  <td className="schoolpupils nameofschools">
                    {items?.DataArray?.schoolName}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.subSchoolCount}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.wondeSchoolCount}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.wondeSchoolPercentage}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="schoolpupils nameofschools">Total KS5</td>
                <td className="schoolpupils miss-session">{countschoolKs5}</td>
                <td className="schoolpupils miss-session">{WondeSchoolks5}</td>
                <td className="schoolpupils miss-session">
                  {schoolonwondeks5}
                </td>
              </tr>

              <tr>
                <td className="schoolpupils nameofschools">Total SSA</td>
                <td className="schoolpupils miss-session">{countschoolSSA}</td>
                <td className="schoolpupils miss-session">{wondwschoolssa}</td>

                <td className="schoolpupils miss-session">{schoolwondeper}</td>
              </tr>

              <tr>
                <td className="schoolpupils nameofschools">Grand Total</td>
                <td className="schoolpupils miss-session">{countschool}</td>
                <td className="schoolpupils miss-session">{WondeSchool}</td>
                <td className="schoolpupils miss-session">{perWonde}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* <div className="custom-head">
        <Footer />
      </div> */}
    </>
  );
};

export default Summary;
