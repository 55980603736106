import React, { useContext, memo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const HeaderLink = (props) => {
  //console.log(props)

  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <Link to={`${props.link}`} onClick={props.closeLinks}>
      {console.log(props.link)}
      <li
        id="activeId"
        className={`flex flex-row items-center nav-link restab res${
          props?.linkName
        } ${props.link === history.location.pathname && "active"}`}
      >
        <span className="txtclr">{props.linkIcon}</span>
        <span className="txtclr">{props.linkName}</span>
      </li>
    </Link>
  );
};

const areEqual = (prevProps, nextProps) => {
  // console.log(prevProps.linkIcon.type.name === nextProps.linkIcon.type.name)
  if (prevProps.linkIcon.type.name === nextProps.linkIcon.type.name) {
    return true;
  } else {
    return false;
  }
};

export default memo(HeaderLink, areEqual);
// export default HeaderLink;
