import React from "react";
import logoSrc from "../asset/images/logo/header_main_logo.png";
import FooterLink from "./FooterLink";
import { routeUrl } from "./utils/RouteUrl";

class Footer extends React.Component {
  render() {
    return (
      <div className="my-5 padding-custom-hf flex flex-col md:flex-row gap-4 md:justify-between">
        <div className="logo-nav md:flex-1 xl:flex xl:gap-4 xl:items-center">
          <div className="logo">
            <img
              src={logoSrc}
              width="108"
              height="40"
              alt="school asset logo"
            />
          </div>
          <div className=" ">
            <div className="footer-copy-text">
              © Copyright 2010-2025. Stat Solutions Ltd. All Rights Reserved.
            </div>
          </div>
        </div>
        <ul className=" footer-menu setting-logout flex flex-wrap gap-3 md:flex-3 md:items-end xl:items-center xl:gap-3">
          <FooterLink target="_blank" link="" linkName=" ASSET for Schools" />
          <FooterLink
            target="_blank"
            link={routeUrl.contact_us}
            linkName="Contact us"
          />
          <FooterLink
            link={routeUrl.privacyAndPolicy}
            linkName="Privacy policy"
          />
          <FooterLink
            target="_blank"
            link={routeUrl.termsAndConditions}
            linkName="Term & Conditions"
          />
        </ul>
      </div>
    );
  }
}

export default Footer;
